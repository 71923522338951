@import "../../../lib/toastr/scss/toastr";

.toast-success {
  background-color: #51a351;
  background-color: #34bfa3;
}
.toast-error {
  background-color: #bd362f;
  background-color: #f4516c;
}
.toast-info {
  background-color: #2f96b4;
  background-color: #36a3f7;
}
.toast-warning {
  background-color: #f89406;
  background-color: #ffb822;
}

#toast-container > div {
  margin: 0 0 6px !important;
}

.toast-top-center,
.toast-top-full-width {
  top: 12px;
}
