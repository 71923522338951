//
// Variables
//

$control-button-top: 9px !default;
$control-button-size: 14px !default;
$control-button-color: white !default;
$open-button-background: #31b131 !default;
$close-button-background: #d33333 !default;

//
// Mixins
//
@mixin control() {
  display: block;
  position: absolute;
  color: $control-button-color;
  border: 2px solid white;
  border-radius: 14px;
  box-shadow: 0 0 3px #444;
  box-sizing: content-box;
  text-align: center;
  text-indent: 0 !important;

  // Use Courier New because it is common and consistent
  font-family: 'Courier New', Courier, monospace;
  line-height: 14px;
}

@mixin control-open() {
  content: '+';
  background-color: $open-button-background;
}

@mixin control-close() {
  content: '-';
  background-color: $close-button-background;
}

//
// Table styles
//
table.dataTable {
  // Styling for the `inline` type
  &.dtr-inline.collapsed > tbody {
    > tr {
      > td.child,
      > th.child,
      > td.dataTables_empty {
        cursor: default !important;

        &:before {
          display: none !important;
        }
      }
    }

    > tr[role="row"] > td:first-child,
    > tr[role="row"] > th:first-child {
      position: relative;
      padding-left: 30px;
      cursor: pointer;

      &:before {
        top: $control-button-top;
        left: 4px;
        height: $control-button-size;
        width: $control-button-size;
        @include control;
        @include control-open;
      }
    }

    > tr.parent {
      > td:first-child:before,
      > th:first-child:before {
        @include control-close;
      }
    }
  }

  // DataTables' `compact` styling
  &.dtr-inline.collapsed.compact > tbody {
    > tr > td:first-child,
    > tr > th:first-child {
      padding-left: 27px;

      &:before {
        top: 5px;
        left: 4px;
        height: 14px;
        width: 14px;
        border-radius: 14px;
        line-height: 14px;
        text-indent: 3px;
      }
    }
  }

  // Styling for the `column` type
  &.dtr-column > tbody {
    > tr > td.control,
    > tr > th.control {
      position: relative;
      cursor: pointer;

      &:before {
        top: 50%;
        left: 50%;
        height: 16px;
        width: 16px;
        margin-top: -10px;
        margin-left: -10px;
        @include control;
        @include control-open;
      }
    }

    > tr.parent {
      td.control:before,
      th.control:before {
        @include control-close;
      }
    }
  }

  // Child row styling
  > tbody > tr.child {
    padding: 0.5em 1em;

    &:hover {
      background: transparent !important;
    }

    ul.dtr-details {
      display: inline-block;
      list-style-type: none;
      margin: 0;
      padding: 0;

      > li {
        border-bottom: 1px solid #efefef;
        padding: 0.5em 0;

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }

    span.dtr-title {
      display: inline-block;
      min-width: 75px;
      font-weight: bold;
    }

    span.dtr-data {
    }
  }
}

div.dtr-modal {
  position: fixed;
  box-sizing: border-box;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  z-index: 100;
  padding: 10em 1em;

  div.dtr-modal-display {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 50%;
    height: 50%;
    overflow: auto;
    margin: auto;
    z-index: 102;
    overflow: auto;
    background-color: #f5f5f7;
    border: 1px solid black;
    border-radius: 0.5em;
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.6);
  }

  div.dtr-modal-content {
    position: relative;
    padding: 1em;
  }

  div.dtr-modal-close {
    position: absolute;
    top: 6px;
    right: 6px;
    width: 22px;
    height: 22px;
    border: 1px solid #eaeaea;
    background-color: #f9f9f9;
    text-align: center;
    border-radius: 3px;
    cursor: pointer;
    z-index: 12;

    &:hover {
      background-color: #eaeaea;
    }
  }

  div.dtr-modal-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 101;

    background: rgba(0, 0, 0, 0.6);
  }
}

@media screen and (max-width: 767px) {
  div.dtr-modal div.dtr-modal-display {
    width: 95%;
  }
}
