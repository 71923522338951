$table-selected: rgba(172, 186, 213, 0.45) !default;

@import "../../../lib/datatables/scss/dataTables.bootstrap";
@import "../../../lib/datatables/extentions/responsive/scss/responsive.bootstrap";
@import "../../../lib/datatables/extentions/fixedheader/scss/fixedHeader.bootstrap";
@import "../../../lib/datatables/extentions/select/scss/select.dataTables";
@import "../../../lib/datatables/extentions/buttons/scss/buttons.bootstrap";


.dataTables_wrapper {
  .dataTables_length, .dataTables_info {
    display: inline-block;
  }

  .dataTables_length + .dataTables_info {
    margin-left: 10px;
  }
}

table.dataTable {
  tbody {
    > tr.selected:hover,
    > tr.selected:hover > td,
    > tr.selected,
    > tr:hover > .selected,
    > tr > .selected {
        background: $table-selected !important;
    }
  }

  tbody td.select-checkbox,
  tbody th.select-checkbox {
    width: 32px;
    padding: 0;

    > span {
      display: block;
      width: 32px;
    }

    &:before,
    &:after {
      transform: translateX(-50%);
      margin-left: 0;
    }
  }

  thead th.select-checkbox {
    width: 16px;
  }

  tr.selected {
    td.select-checkbox,
    th.select-checkbox {
      &:after {
        margin-top: -7px;
        margin-left: -0;
      }
    }
  }

  &.footer-right {
    tfoot th {
      text-align: right;
    }
  }
}

/***
Sorting
***/

table.dataTable thead {
  .sorting,
  .sorting_asc,
  .sorting_desc,
  .sorting_asc_disabled,
  .sorting_desc_disabled {
    cursor: pointer;

    &:after {
      display: none;
    }
  }
}


.iconSettings {
  margin-top    : 5px;
  margin-bottom : 10px;
  font-size     : 12px;
  position      : relative;
  top           : 1px;
  display       : inline-block;
  font-family   : 'Glyphicons Halflings';
  font-style    : normal;
  font-weight   : 400;
  line-height   : 1;
  -webkit-font-smoothing : antialiased;
}

table.dataTable {
  thead th.details-control {
    width: 15px;
  }

  tbody td.details-control,
  tbody th.details-control {
    cursor     : pointer;
    text-align : center;

    &:before {
      margin-top    : 5px;
      margin-bottom : 10px;
      font-size     : 12px;
      position      : relative;
      top           : 1px;
      display       : inline-block;
      font-family   : 'Glyphicons Halflings';
      font-style    : normal;
      font-weight   : 400;
      line-height   : 1;
      -webkit-font-smoothing : antialiased;

      content : '\2b';
    }
  }

  tbody tr.details-shown td.details-control,
  tbody tr.details-shown th.details-control {
    &:before {
      content : '\2212';
    }
  }
}

// Typography

table.dataTable {
	th.dt-left,
	td.dt-left {
		text-align: left;
	}

	th.dt-center,
	td.dt-center,
	td.dataTables_empty {
		text-align: center;
	}

	th.dt-right,
	td.dt-right {
		text-align: right;
	}

	th.dt-justify,
	td.dt-justify {
		text-align: justify;
	}

	th.dt-nowrap,
	td.dt-nowrap {
		white-space: nowrap;
	}

	thead,
	tfoot {
		th.dt-head-left,
		td.dt-head-left {
			text-align: left;
		}

		th.dt-head-center,
		td.dt-head-center{
			text-align: center;
		}

		th.dt-head-right,
		td.dt-head-right {
			text-align: right;
		}

		th.dt-head-justify,
		td.dt-head-justify {
			text-align: justify;
		}

		th.dt-head-nowrap,
		td.dt-head-nowrap {
			white-space: nowrap;
		}
	}

	tbody {
		th.dt-body-left,
		td.dt-body-left {
			text-align: left;
		}

		th.dt-body-center,
		td.dt-body-center {
			text-align: center;
		}

		th.dt-body-right,
		td.dt-body-right {
			text-align: right;
		}

		th.dt-body-justify,
		td.dt-body-justify {
			text-align: justify;
		}

		th.dt-body-nowrap,
		td.dt-body-nowrap {
			white-space: nowrap;
		}
	}
}
table {
  td.highlight,
  td.dt-highlight,
  th.dt-highlight {
      background-color: whitesmoke !important;
  }
}

table {
  tr.dt-highlight-left > td:first-child {
    position: relative;

    @mixin dt-highlight {
      content: '';
      position: absolute;
      left: -6px;
      top: 0;
      bottom: 0;
      width: 6px;
    }

    &:not(.select-checkbox):before,
    &.select-checkbox span:before {
      @include dt-highlight;
      background: #32c5d2;
    }
  }

  tr.dt-highlight-left.dt-hl-color-danger > td:first-child {
    &:not(.select-checkbox):before,
    &.select-checkbox span:before {
      background: #ed6b75;
    }
  }
  tr.dt-highlight-left.dt-hl-color-warning > td:first-child {
    &:not(.select-checkbox):before,
    &.select-checkbox span:before {
      background: #f9e491a3;
    }
  }
  tr.dt-highlight-left.dt-hl-color-primary > td:first-child {
    &:not(.select-checkbox):before,
    &.select-checkbox span:before {
      background: #337ab7;
    }
  }
  // This option is used for external user that apparently can't handle green not being green.
  // Exact use case does not use the other states hence not creating all the different striping combinations
  tr.dt-highlight-left.dt-hl-color-sma-green {
    $green-color: #4caf50;

    & > td:first-child {
      &:not(.select-checkbox):before,
      &.select-checkbox span:before {
        background: $green-color;
      }
    }

    & > td {
      background: rgba($green-color, 0.07);
    }

    & .badge-success {
      background: $green-color;
    }
  }
  tr.dt-highlight-left.dt-hl-color-warning.dt-hl-color-danger > td:first-child {
    &:not(.select-checkbox):before,
    &.select-checkbox span:before {
      background: repeating-linear-gradient(45deg, #ed6b75, #ed6b75 6px, #f9e491a3 6px, #f9e491a3 12px);
    }
  }
  tr.dt-highlight-left.dt-hl-color-primary.dt-hl-color-danger > td:first-child {
    &:not(.select-checkbox):before,
    &.select-checkbox span:before {
      background: repeating-linear-gradient(45deg, #337ab7, #337ab7 6px, #ed6b75 6px, #ed6b75 12px);
    }
  }
  tr.dt-highlight-left.dt-hl-color-primary.dt-hl-color-warning > td:first-child {
    &:not(.select-checkbox):before,
    &.select-checkbox span:before {
      background: repeating-linear-gradient(45deg, #337ab7, #337ab7 6px, #f9e491a3 6px, #f9e491a3 12px);
    }
  }
  tr.dt-highlight-left.dt-hl-color-primary.dt-hl-color-danger.dt-hl-color-warning > td:first-child {
    &:not(.select-checkbox):before,
    &.select-checkbox span:before {
      background: repeating-linear-gradient(45deg, #337ab7, #337ab7 6px, #ed6b75 6px, #ed6b75 12px, #f9e491a3 12px, #f9e491a3 18px);
    }
  }

  tr.dt-highlight-sides {
    --dt-highlight-sides-background: #32c5d2;
    & > td:first-child, & > td:last-child{
      position: relative;
      &:not(.select-checkbox):before,
      &.select-checkbox span:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 6px;
        background: var(--dt-highlight-sides-background);
      }
    }
    & > td:first-child{
      &:not(.select-checkbox):before,
      &.select-checkbox span:before {
        left: -6px;
      }
    }

   & > td:last-child{
      &:not(.select-checkbox):before,
      &.select-checkbox span:before {
        right: -6px;
      }
    }
  }

}
