.select2-container .select2-selection.select2-selection--multiple .select2-search.select2-search--inline .select2-search__field:not([placeholder='']) {
  width: 100% !important;
  margin-right: 100000px;
}

// Checkmark
.select2-results__option[aria-selected=true]:before {
  content: '\2713';
  display: inline-block;
  position: relative;
  vertical-align: middle;
  float: right;
}

.select2-container--bootstrap {
  .select2-selection__clear {
    margin-left: 10px;
  }

  .select2-results__group {
    color: #444;
    font-size: 14px;
  }

  .select2-results__options--nested {
    padding-left: 10px;
  }
}

.select2-results__group[aria-selected] {
    cursor: pointer;

    &[aria-selected=true] {
      background-color: #ddd;
    }

    &.select2-results__option--highlighted[aria-selected] {
      background-color: #5897fb;
      color: white;
    }
}
